import React from 'react';
import { NavLink} from 'react-router-dom';

const Toolbar : React.SFC = () => {

  return (
    <header>
      <nav>
        <a href='#' className='navbar-brand'>Nasjonale prøver</a>
        <div className='navbar'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/5-trinn'>5. trinn</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/8-9-trinn'>8 og 9. trinn</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/about'>Om</NavLink>
            </li>
          </ul>
        </div>
      </nav>
      
    </header>
    
  );
};

export default Toolbar;