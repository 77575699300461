import React from 'react';

interface ILoadSpinnerProps {
    loading: boolean;
}

const LoadSpinner:  React.FC<ILoadSpinnerProps> = ({loading}) => {
    const component = <div>Loading...</div>;
    return loading ? component : null;
}

export default LoadSpinner;