import React, { useState, useEffect } from 'react';

import {schoolApi as api} from '../../api/schoolDataApi';
import {ISchool, ISchoolShowing} from '../../domain/school';

import {SchoolMap} from '../school/school-map';
import {SchoolsPane} from '../school/schools-pane';
import LoadSpinner from '../shared/load-spinner';

import './elementary-school-page.css';
import '../school/school-page.css';

const MAX_SCHOOLS_TO_COMPARE = 10;

export const ElementarySchoolPage: React.SFC = () =>  {

    const [schools, setSchools] = useState<ISchoolShowing[]>([]);
    const [schoolLayer, setSchoolLayer] = useState<any>(undefined);

    useEffect(() =>{
        loadSchoolLayer();
    }, [])

    return (
        <div id='elementary-page'>
            
            <div className='school-content'>    
                <div className='map-container'>
                    <LoadSpinner loading={!schoolLayer}/>
                    {schoolLayer && <SchoolMap 
                        id='elementary-map' 
                        schoolLayer={schoolLayer} 
                        popupFactory={loadSchoolData}
                        onAddToComparing={onAddToComparing}
                    />}
                </div>
                <div className='school-data-pane'>
                    <SchoolsPane schools={schools} onRemove={onRemoveFromComparing}/>
                </div>
            </div>
        </div>
    );

    async function  loadSchoolLayer() {
        const schoolLayer = await api.getBarneskoleLayer();
        setSchoolLayer(schoolLayer);
    }

    function loadSchoolData(id: number): Promise<ISchool> {
        return api.getElementarySchoolData(id);
    }

    function onAddToComparing(school: ISchool) {
        const newSchools = [...schools];
        const existingSchool = newSchools.find(s => s.name.id === school.id);
        if (existingSchool) {
            existingSchool.school = school;
        } else {
            newSchools.push({name: {id: school.id, name: school.name}, school});
            if (newSchools.length > MAX_SCHOOLS_TO_COMPARE) {
                newSchools.splice(0, newSchools.length - MAX_SCHOOLS_TO_COMPARE)
            }
        }

        setSchools(newSchools);
    }

    function onRemoveFromComparing(school: ISchoolShowing) {
        const newSchools = [...schools.filter(s => s.name.id !== school.name.id)];
        setSchools(newSchools);
    }
}

export default ElementarySchoolPage;