import React, { useState, useEffect } from 'react';
import {ISchool} from '../../domain/school';

import {Map, TileLayer, GeoJSON} from 'react-leaflet';
import {IMapPopup, SchoolPopup} from './school-popup';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';

interface ISchoolMapComponentProps {
    id: string;
    schoolLayer?: GeoJSON.GeoJsonObject;
    popupFactory: (id: number) => Promise<ISchool>;
    onAddToComparing: (school: ISchool) => void|Promise<void>
}

interface ISchoolMapExtent {
    center: [number, number];
    zoom: number;
}

export const SchoolMap: React.SFC<ISchoolMapComponentProps> = ({id, schoolLayer, popupFactory, onAddToComparing}) => {

    const [extent, setExtent] = useState<ISchoolMapExtent>({center: [59.9, 10.7], zoom: 11});
    const [popup, setPopup] = useState<IMapPopup|undefined>(undefined);

    useEffect(() => {
        const extentAsString = localStorage.getItem('map_extent');
        if (!extentAsString) {
            return;
        }

        const extent: ISchoolMapExtent = JSON.parse(extentAsString);

        setExtent(extent);
    }, []);

    const position: [number, number] = [extent.center[0], extent.center[1]];
    const zoom = extent.zoom
    
    return (
        <div id={id} className="map-placeholder"> 
            <Map center={position} zoom={zoom} className="map-placeholder" onzoomend={onMapExtentChanged}
                    onmoveend={onMapExtentChanged}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    minZoom={2}
                    maxZoom={30}
                    
                />
                {schoolLayer 
                    ? <GeoJSON data={schoolLayer} pointToLayer={convertPointToLayer} onclick={onLayerClicked}/>
                    : null}
                <SchoolPopup 
                    popup={popup} 
                    onClose={() => onPopupClosed()} 
                    onAddToComparing={onAddToComparing}/>
            </Map>
        </div>
    );

    function onLayerClicked(target: any) {

        const schoolId = target.layer.schoolId;
        const latlng: L.LatLng = target.latlng;

        showPopup(schoolId, latlng);
    }

    async function showPopup(schoolId: number, latlng: L.LatLng) {
        if (popup && !popup.school) {
            return;
        }
       
        setPopup({latlng})
      
        const school: ISchool = await popupFactory(schoolId);

        setPopup({school, latlng})
    }

    function onPopupClosed() {
        setPopup(undefined);
    }

    function onMapExtentChanged(this: any, e: any) {
        const center: L.LatLng = this.getCenter();
        const zoom = this.getZoom();
        const extent: ISchoolMapExtent = {zoom, center: [center.lat, center.lng]};
        localStorage.setItem('map_extent', JSON.stringify(extent));
    }

    function convertPointToLayer(feature: any, latlng: L.LatLng) {

        const geojsonMarkerOptions = {
            radius: 8,
            fillColor: "#ff7800",
            color: "#000",
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8
        };

        const marker = L.circleMarker(latlng, geojsonMarkerOptions);

        const schoolId = feature.properties.id;
        const schoolName = feature.properties.school_name;
        (marker as any).schoolId = schoolId;
        (marker as any).schoolName = schoolName;

        return marker;
    }
}
