import React from 'react';
import {ISchool, IClassSubjectTimeSerieEntry, IClassSubjectTimeSerie} from '../../domain/school';

import {Popup} from 'react-leaflet';
import LoadSpinner from '../shared/load-spinner';
import L from 'leaflet';
import './school-popup.css';

export interface IMapPopup {
    school?: ISchool;
    latlng: L.LatLng;
}

interface ISchoolPopupProps {
    popup?: IMapPopup;
    onClose: () => void | Promise<void>;
    onAddToComparing: (result: ISchool) => void | Promise<void>
}

export const SchoolPopup: React.SFC<ISchoolPopupProps> = ({popup, onClose, onAddToComparing}) => {

    if (!popup) {
        return null;
    }

    const school = popup.school;
   

    const lastResults = school && school.results.length > 0 ? school.results.map(r => ({result : r, entry: r.entries.filter(e => e.value)[0]})).filter(r => r.entry) : [];
    const yearInterval = getLastYearAsText(lastResults.map(r => r.entry.year));

    return (
        <Popup position={popup.latlng} onClose={() => onClose()}>
            <LoadSpinner loading={!popup.school}/>
            {school && (
              <div className="popup">
                <h1>{school.name}</h1>
                <h3>{school.address.kommune} </h3>
                
                {lastResults.length !== 0 ? <>
                    <h2>Siste resultater ({yearInterval})</h2>
                    {lastResults.map(r => renderResult(r.result, r.entry))}
                    <button className="btn-info" onClick={() =>onAddToComparing(school)}>Legg til</button>
                </>
                : <div>Ingen data</div>}
              </div>
            )}
        </Popup>
    );

    function getLastYearAsText(years: number[]){

      if (!school || !school.results) {
        return '';
      }

      years = years.sort((y1, y2) => y2 - y1);
    
      if (!years.length) {
          return '';
      }
    
      const lastYear = years[0];
      const firstYear = years[years.length -1];
    
      if (lastYear === firstYear) {
          return firstYear.toString();
      }       
    
      return `${firstYear}-${lastYear}`; 
    }

    function renderResult(result: IClassSubjectTimeSerie, entry: IClassSubjectTimeSerieEntry) {

        return (
            <div key={result.subject + '_' + result.schoolClass} className='result-table-row'>
                <div className='title'>{result.subject}<span className='secondary-text'>{` (${result.schoolClass}. klasse)`}</span></div>
                <div className='value'>{`${entry.value}`}</div>
            </div>
        );
    }
}