import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import ElementarySchoolPage from './components/elementary/elementary-school-page';
import UpperSchoolPage from './components/upperschool/upper-school-page';
import AboutPage from './components/about/about-page';
import Toolbar from './components/shared/toolbar';

const App: React.SFC = () => {

    return (
      <div className="App">
        <div  className="main-page">
          <Router>
            <Toolbar/> 
            <Switch>
              <Route path="/5-trinn" component={ElementarySchoolPage} />
              <Route path="/8-9-trinn" component={UpperSchoolPage} />
              <Route path="/about" component={AboutPage} />
              <Route component={ElementarySchoolPage} />
            </Switch>
          </Router>
        </div>
      </div>
    );
}

export default App;
