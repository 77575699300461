import React from 'react';
import { ISchoolAddress, ISchoolContact} from '../../domain/school';

interface IAddressSectionProps {
  address: ISchoolAddress;
  contact: ISchoolContact;
  expanded: boolean;
  toggleExpanded : () => void;
}

export const ContactSection: React.FC<IAddressSectionProps> = ({address, contact, expanded, toggleExpanded}) => {

    if (!expanded) {
      return (<div className="school-contact" >
          <span className="school-contact-header" onClick={() => toggleExpanded()}>Adresse</span>
      </div>);
    }

    return (
      <div className="school-contact" >
          <span className="school-contact-header" onClick={() => toggleExpanded()}>Adresse</span>
          <div className="address">
              <div>{address.gateadresse}</div>
              <div>{address.postkode} {address.poststed}</div>
          </div>
          {contact && contact.homepage && 
            <div className="homepage">
                <a href={contact.homepage}>{contact.homepage}</a>
            </div>
          }
      </div>
    );
}
