import React from 'react';

export const AboutPage: React.SFC = () =>  {

    return (
        <div id='about-page'>
            <p className='intro-text'>Her finner du data om skoler og nasjonale prøver for 5., 8. og 9. trinn</p>
            <h2>Datakilde</h2>
            <p>Data som vises ble hentet fra <a href='https://skoleporten.udir.no/'>Skoleporten</a></p>
            <h2>Karakterer</h2>
            <p>Jo høyere desto bedre. Utdanningsdirektoratet beskriver intervalet 43-56 som midten (mestringsnivå 2)</p>
            <h2>Tolkning</h2>
            <p>Nasjonale prøver forteller ikke hele sannheten om en skole. Vil du virkelig vurdere/sammenligne to skoler se gjerne på flere faktorer</p>
            <h2>Jeg fant en feil</h2>
            <p>Ting skjer. Send meg gjerne <a href='mailto:skoler@pm.me'>e-mail</a></p>
        </div>
    );
}

export default AboutPage;