import React, { useState } from 'react';
import { IClassSubjectTimeSerie, IClassSubjectTimeSerieEntry} from '../../domain/school';

interface IResultSectionProps {
  result: IClassSubjectTimeSerie;
}

export const ResultSection: React.SFC<IResultSectionProps> = ({result}) => {

  const [expanded, setExpanded] = useState<boolean>(false);

  const realResults = result.entries.filter(e => e.value);
  const lastResult = realResults[0];

    return (
        <div className="result-tile">
            <div className="result-tile-header" onClick={() => setExpanded(!expanded)}>
              {!!lastResult 
                ? (<>
                    <div>{result.subject}<span className='secondary-text'>{` (${result.schoolClass}. klasse)`}</span></div>
                    <div>{lastResult.value}</div>
                  </>)
                : (<div>Ingen data</div>)
              }
            </div>
            {expanded && realResults.map(e => renderSchoolResultEntry(e))}
        </div>
    );

    function renderSchoolResultEntry(entry: IClassSubjectTimeSerieEntry) {

      return (
          <div className="result-entry" key={entry.year}>
             <div>{entry.year}</div>
             <div>{entry.value}</div>
          </div>
      );
  }
}
