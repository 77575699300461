import {ISchool, IClassSubjectTimeSerie} from '../domain/school';
import axios from 'axios';

class SchoolApi {
    public async getSchoolDataMock(id: number) : Promise<ISchool> {
        return await Promise.resolve({
            id,
            name: 'Sample school name',
            address: {
                fylke: 'Akershus',
                kommune: 'Asker',
                postkode: '1388',
                poststed: 'Asker',
                gateadresse: 'Kirkeveien 100'
            },
            contact: {
                homepage: '',
                email: ''
            },
            results: [
                {
                    schoolClass: 5,
                    subject: 'Lese',
                    entries: [
                        {
                            year: 2017,
                            value: 51
                        },
                        {
                            year: 2018,
                            value: 55
                        }
                    ]
                },
                {
                    schoolClass: 5,
                    subject: 'Engelsk',
                    entries: [
                        {
                            year: 2017,
                            value: 45
                        },
                        {
                            year: 2018,
                            value: 48
                        }
                    ]
                }
            ]
        });
    }

    private elementarySchoolLayer: any;
    private upperSchoolLayer: any;

    public async getSchoolData(id: number) : Promise<ISchool> {
        const response = await axios.get<ISchool>(`https://e7s4ads4b1.execute-api.eu-north-1.amazonaws.com/default/skoler-data?id=${id}`);
        const school = response.data;
        school.results = SchoolApi.sortResults(school.results);
        return school;
    }

    public async getElementarySchoolData(id: number) : Promise<ISchool> {
        const response = await axios.get<ISchool>(`https://e7s4ads4b1.execute-api.eu-north-1.amazonaws.com/default/skoler-data?id=${id}&type=barneskole`);
        const school = response.data;
        school.results = SchoolApi.sortResults(school.results.filter(r => r.schoolClass <= 7));
        return school;
    }

    public async getUpperSchoolData(id: number) : Promise<ISchool> {
        const response = await axios.get<ISchool>(`https://e7s4ads4b1.execute-api.eu-north-1.amazonaws.com/default/skoler-data?id=${id}&type=ungdomsskole`);
        const school = response.data;
        school.results = SchoolApi.sortResults(school.results.filter(r => r.schoolClass > 7));
        return school;
    }

    public async getBarneskoleLayer(): Promise<any> {
        if (!this.elementarySchoolLayer) {
            const layer = await axios.get('https://skoler.info/geo/barneskoler.geojson');
            //const layer = await axios.get('https://e7s4ads4b1.execute-api.eu-north-1.amazonaws.com/default/skoler-layer?type=barneskole');
            this.elementarySchoolLayer = layer.data;
        }

        return this.elementarySchoolLayer;
    }

    public async getUngdomsskoleLayer(): Promise<any> {

        if (!this.upperSchoolLayer) {
            const layer = await axios.get('https://skoler.info/geo/ungdomsskoler.geojson');
            //const layer = await axios.get('https://e7s4ads4b1.execute-api.eu-north-1.amazonaws.com/default/skoler-layer?type=ungdomsskole');
            this.upperSchoolLayer = layer.data;
        }

        return this.upperSchoolLayer;
    }

    private static sortResults(results: IClassSubjectTimeSerie[]) :IClassSubjectTimeSerie[] {
        return  results
            .map(r => ({...r, entries: r.entries.sort((a, b) => b.year - a.year)}))
            .sort(SchoolApi.compareClassSubjectResult);
    } 

    private static compareClassSubjectResult(a: IClassSubjectTimeSerie, b: IClassSubjectTimeSerie): number {
        if (a.subject === b.subject){
            return a.schoolClass - b.schoolClass;
        }

        return a.subject > b.subject ? 1 : -1;
    }
}

export const schoolApi = new SchoolApi();