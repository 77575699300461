import React, { useState } from 'react';
import {ISchool, ISchoolShowing} from '../../domain/school';

import LoadSpinner from '../shared/load-spinner';
import {ResultSection} from './result-section';
import {ContactSection} from './contact-section';
import './school-pane.css';

interface ISchoolPaneProps {
  schoolShowing: ISchoolShowing;
  onRemove: (schoolShowing: ISchoolShowing) => void;
}

export const SchoolPane: React.SFC<ISchoolPaneProps> = ({schoolShowing, onRemove}) => {

    const [expandedAddress, setExpandedAddress] = useState<boolean>(false);

    return (
      <div className="school-pane" key={'school-pane-' + schoolShowing.name.id}>
          <h3>{schoolShowing.name.name}</h3>
          <LoadSpinner loading={!schoolShowing.school}/>
          {schoolShowing.school && 
              renderSchool(schoolShowing.school)
          }
          <a className="close-button" onClick={()=> onRemove(schoolShowing)}>x</a>
      </div>
    );

    function renderSchool(school: ISchool) {

        return (
            <>
               <ContactSection address={school.address} contact={school.contact} expanded={expandedAddress} toggleExpanded={() => setExpandedAddress(!expandedAddress)}/>
               {school.results.map(r => <ResultSection result={r} key={r.subject}/>)}
            </>
        );
    }
}
