import React from 'react';
import { ISchoolShowing } from '../../domain/school';

import {SchoolPane} from './school-pane';

interface ISchoolsPaneProps {
    schools: ISchoolShowing[];
    onRemove: (school: ISchoolShowing) => void | Promise<void>;
}

export const SchoolsPane: React.SFC<ISchoolsPaneProps> = ({schools, onRemove}) => {

    return (
        <div className="inner-container">
            {schools.map(s => <SchoolPane key={s.name.name} schoolShowing={s} onRemove={s => onRemove(s)}/>)}
        </div>
    );
}
